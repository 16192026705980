import {Rating} from './rating';

export class ProductScore {
    constructor(
        readonly imageRating: Rating,
        readonly imageCountRating: Rating,
        readonly locationRating: Rating,
        readonly optionRating: Rating,
        readonly optionCount: Rating,
        readonly subproductRating: Rating,
        readonly subproductCount: Rating,
        readonly videoRating: Rating,
        readonly contactRating: Rating) {
    }
}
