import { None, Option } from 'funfix-core';
import { parseNumber } from '../core';
import { JsonBuilder } from '../core/json-builder';
import { SimpleJsonSerializer } from '../core/json-serializer';

const numberOfKidsKey = 'number_of_kids';
const numberOfAdultsKey = 'number_of_adults';

export class Travellers {

    constructor(numberOfKids: Option<number> = None, numberOfAdults: Option<number> = None) {
        this.numberOfKids = numberOfKids;
        this.numberOfAdults = numberOfAdults;
    }
    readonly numberOfAdults: Option<number>;
    readonly numberOfKids: Option<number>;
}

export class TravellersJsonSerializer extends SimpleJsonSerializer<Travellers> {
    static instance: TravellersJsonSerializer = new TravellersJsonSerializer();

    fromJsonImpl(obj: any): Travellers {
        return new Travellers(
            parseNumber(obj[numberOfKidsKey]),
            parseNumber(obj[numberOfAdultsKey]));
    }

    protected toJsonImpl(travellers: Travellers, builder: JsonBuilder = new JsonBuilder()): JsonBuilder {
        return builder
            .addOptional(numberOfKidsKey, travellers.numberOfKids)
            .addOptional(numberOfAdultsKey, travellers.numberOfAdults);
    }
}
