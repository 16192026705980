import {Either, Option} from 'funfix-core';
import {List, Map, Set} from 'immutable';
import {
    descriptionKey,
    EitherUtils,
    idKey,
    JsonBuilder,
    labelKey,
    MapUtils,
    nameKey,
    OptionUtils,
    parseList,
    parseNumber,
    parseString,
    SimpleJsonSerializer,
} from '../core';

export class EnumConstantMap {

    constructor(readonly enums: List<EnumConstant>) {
    }

    private byId: Map<number, EnumConstant> = MapUtils.buildMapFromListOptional(this.enums, e => e.id);
    private byName: Map<string, EnumConstant> = MapUtils.buildMapFromListOptional(this.enums, e => e.name);
    private byNameLower: Map<string, EnumConstant> = MapUtils.buildMapFromListOptional(this.enums, e => e.name.map(x => x.toLowerCase()));

    static parseFromArray(arr: any): EnumConstantMap {
        return new EnumConstantMap(parseList(arr, x => EnumConstantJsonSerializer.instance.fromJson(x)));
    }

    containsOneIdOf(s: Set<number>): boolean {
        return this.byId.keySeq().some(x => s.contains(x));
    }

    containsOneNameOf(s: Set<string>): boolean {
        return this.byName.keySeq().some(x => s.contains(x));
    }

    getById(n: number): Option<EnumConstant> {
        return Option.of(this.byId.get(n));
    }

    getByIdEither(n: number): Either<string, EnumConstant> {
        return EitherUtils.liftEither(this.byId.get(n), 'Unable to find enum');
    }

    getByName(s: string): Option<EnumConstant> {
        return Option.of(this.byName.get(s));
    }

    getByNameLower(s: string): Option<EnumConstant> {
        return Option.of(this.byNameLower.get(s.toLowerCase()));
    }

    getByNames(s: Set<string>): EnumConstantMap {
        const lower = s.map(x => x.toLowerCase());
        return new EnumConstantMap(this.enums.filter(x => x.name.exists(n => lower.contains(n.toLowerCase()))));
    }

    getExistsByName(s: Option<string>): boolean {
        return s.exists(x => this.getByNameLower(x).nonEmpty());
    }

    getExistsByNameIfNotPresent(s: Option<string>): boolean {
        return s.isEmpty() || this.getExistsByName(s);
    }

    getIdByName(s: string): Option<number> {
        return this.getByName(s).flatMap(x => x.id);
    }

    getIdByNameLower(s: string): Option<number> {
        return this.getByNameLower(s).flatMap(x => x.id);
    }

    getIdByOptName(o: Option<string>): Option<number> {
        return o.flatMap(n => this.getIdByNameLower(n));
    }

    getIdsByNames(s: List<string>): List<number> {
        return OptionUtils.flattenList(s.map(k => this.getIdByName(k)));
    }

    getLowercaseNames(): Set<string> {
        return this.byNameLower.keySeq().toSet();
    }

    getMissingNames(s: Set<string>): Set<string> {
        return s.subtract(this.getNames());
    }

    getMissingNamesCaseInsensitive(s: Set<string>): Set<string> {
        return s
            .map(x => x.toLowerCase())
            .subtract(this.getNames());
    }

    getNameById(n: number): Option<string> {
        return this.getById(n).flatMap(x => x.name);
    }

    getNames(): Set<string> {
        return this.byName.keySeq().toSet();
    }

    toObjectById(): object {
        return this.byId.map(x => x.name)
            .filter(x => x.nonEmpty())
            .map(y => y.get())
            .toObject();
    }

    toObjectByString(): object {
        return this.byName.map(x => x.id)
            .filter(x => x.nonEmpty())
            .map(y => y.get())
            .toObject();
    }

    toObjectString(): object {
        return this.enums.map(x => x.name)
            .filter(x => x.nonEmpty())
            .map(x => x.get())
            .toJSON();
    }

    update(enumsToUpdate: List<EnumConstant>): EnumConstantMap {
        // Enums can be a bit tricky in the sense that this updates, modifies and touches the entire Enum Map
        // regardless of what procedure is run since this in is a pretty broad and umbrella class
        return new EnumConstantMap(enumsToUpdate.concat(this.enums));
    }

}

export class EnumConstant {
    constructor(
        readonly id: Option<number>,
        readonly name: Option<string>,
        readonly label: Option<string>,
        readonly description: Option<string>,
    ) {
    }

    getDescription(): Option<string> {
        return this.description;
    }

    getDisplayName(): Option<string> {
        return this.label.orElse(this.name);
    }

    getId(): Option<number> {
        return this.id;
    }

    getLabel(): Option<string> {
        return this.label;
    }

    getName(): Option<string> {
        return this.name;
    }
}

export class EnumConstantJsonSerializer extends SimpleJsonSerializer<EnumConstant> {
    static instance: EnumConstantJsonSerializer = new EnumConstantJsonSerializer();

    fromJsonImpl(obj: any): EnumConstant {
        return new EnumConstant(
            parseNumber(obj[idKey]),
            parseString(obj[nameKey]),
            parseString(obj[labelKey]),
            parseString(obj[descriptionKey]));
    }

    protected toJsonImpl(budget: EnumConstant, builder: JsonBuilder = new JsonBuilder()): JsonBuilder {
        return builder
            .addOptional(idKey, budget.id)
            .addOptional(nameKey, budget.name)
            .addOptional(labelKey, budget.label)
            .addOptional(descriptionKey, budget.description);
    }
}
