import {None, Option, Some} from 'funfix-core';
import {List} from 'immutable';
import {
    descriptionKey,
    JsonBuilder,
    langIdKey,
    Languages,
    lengthIdKey,
    optFakeIdxKey,
    OptionUtils,
    prodFakeIdxKey,
    SimpleJsonSerializer,
    typeIdKey,
    Validatable,
    ValidationResult,
    ValidationUtils,
} from '../core';
import {DescriptionTranslations} from './description-translations';
import {Descriptions} from './descriptions';
import {Product} from './product';
import {ProductOption} from './product-option';

const shortId = 252620;
const longId = 252621;
const inclusionsId = 7;
const exclusionsId = 252625;
const meetingPointId = 252626;
const productNameId = 252623;

const len800Id = 59;
const len2000Id = 87;
const len200Id = 17;

// 2^30 -1
const sqlTextMax = 1073741823;

export class DbCoreDescription implements Validatable {

    constructor(
        readonly description: string,
        readonly langId: number,
        readonly typeId: number,
        readonly lenId: number,
        readonly prodFakeIdx: Option<number> = None,
        readonly optFakeIdx: Option<number> = None,
    ) {
    }

    static buildFromOptionalProductDescription(
        descriptions: Option<Descriptions>,
        langid: number,
        fakeIdx: Option<number> = None,
    ): List<DbCoreDescription> {
        return DbCoreDescription.buildFromProductDescriptions(
            descriptions.getOrElse(new Descriptions()), langid, fakeIdx);
    }

    static buildFromProduct(
        product: Product,
        fakeIdx: Option<number> = None,
    ): List<DbCoreDescription> {
        return Languages.getLanguageId('English')
            .map(englishId => DbCoreDescription.buildFromOptionalProductDescription(product.descriptions, englishId, fakeIdx)
                .concat(DbCoreDescription.buildFromProductDescriptionTranslations(product.translations.getOrElse(new DescriptionTranslations()), fakeIdx))
                .concat(DbCoreDescription.buildFromProducts(product.subproducts, fakeIdx))
                .concat(DbCoreDescription.buildFromProductOptions(product, fakeIdx)),
            ).getOrElse(List());
    }

    static buildFromProductDescriptions(
        descriptions: Descriptions,
        langid: number,
        fakeIdx: Option<number> = None): List<DbCoreDescription> {
        return OptionUtils.toList(
            descriptions.short.map(d => new DbCoreDescription(d, langid, shortId, len200Id, fakeIdx)),
            descriptions.long.map(d => new DbCoreDescription(d, langid, longId, len2000Id, fakeIdx)),
            descriptions.title.map(d => new DbCoreDescription(d, langid, productNameId, len2000Id, fakeIdx)),
            descriptions.inclusions.map(d => new DbCoreDescription(d, langid, inclusionsId, len2000Id, fakeIdx)),
            descriptions.exclusions.map(d => new DbCoreDescription(d, langid, exclusionsId, len2000Id, fakeIdx)),
            descriptions.meetingPoint.map(d => new DbCoreDescription(d, langid, meetingPointId, len2000Id, fakeIdx)),
        );
    }

    static buildFromProductDescriptionTranslations(
        descriptions: DescriptionTranslations,
        fakeIdx: Option<number> = None): List<DbCoreDescription> {
        return OptionUtils.map8(
            Languages.getLanguageId('French'),
            Languages.getLanguageId('Spanish'),
            Languages.getLanguageId('Danish'),
            Languages.getLanguageId('Italian'),
            Languages.getLanguageId('Dutch'),
            Languages.getLanguageId('Swedish'),
            Languages.getLanguageId('Chinese'),
            Languages.getLanguageId('German'),
            (frenchId, spanishId, danishId, italianId, dutchId, swedishId, chineseId, germanId) => {
                return DbCoreDescription.buildFromOptionalProductDescription(descriptions.getFrench(), frenchId, fakeIdx)
                    .concat(DbCoreDescription.buildFromOptionalProductDescription(descriptions.getSpanish(), spanishId, fakeIdx))
                    .concat(DbCoreDescription.buildFromOptionalProductDescription(descriptions.getDanish(), danishId, fakeIdx))
                    .concat(DbCoreDescription.buildFromOptionalProductDescription(descriptions.getItalian(), italianId, fakeIdx))
                    .concat(DbCoreDescription.buildFromOptionalProductDescription(descriptions.getDutch(), dutchId, fakeIdx))
                    .concat(DbCoreDescription.buildFromOptionalProductDescription(descriptions.getSwedish(), swedishId, fakeIdx))
                    .concat(DbCoreDescription.buildFromOptionalProductDescription(descriptions.getChinese(), chineseId, fakeIdx))
                    .concat(DbCoreDescription.buildFromOptionalProductDescription(descriptions.getGerman(), germanId, fakeIdx));
            }).getOrElse(List());
    }

    static buildFromProductOption(
        product: Product,
        option: ProductOption,
        prodFakeIdx: Option<number> = None,
        optFakeIdx: Option<number> = None,
    ): List<DbCoreDescription> {
        return Languages.getLanguageId('English')
            .map(englishId => this.buildFromProductOptionDescriptions(option.descriptions, prodFakeIdx, optFakeIdx, englishId)
                .concat(this.buildFromProductOptionDescriptionTranslations(option.translations.getOrElse(new DescriptionTranslations()), prodFakeIdx, optFakeIdx)))
            .getOrElse(List());
    }

    private static buildFromProductOptionDescriptions(
        descriptions: Option<Descriptions>,
        prodFakeIdx: Option<number>,
        optFakeIdx: Option<number>,
        langid: number,
    ): List<DbCoreDescription> {
        return OptionUtils.toList(
            descriptions.flatMap(d => d.short)
                .map(d => new DbCoreDescription(d, langid, shortId, len200Id, prodFakeIdx, optFakeIdx)),
            descriptions.flatMap(d => d.long)
                .map(d => new DbCoreDescription(d, langid, longId, len800Id, prodFakeIdx, optFakeIdx)),
            descriptions.flatMap(d => d.title)
                .map(d => new DbCoreDescription(d, langid, productNameId, len200Id, prodFakeIdx, optFakeIdx)));
    }

    static buildFromProductOptionDescriptionTranslations(
        descriptions: DescriptionTranslations,
        prodFakeIdx: Option<number> = None,
        optFakeIdx: Option<number> = None): List<DbCoreDescription> {
        return OptionUtils.map8(
            Languages.getLanguageId('French'),
            Languages.getLanguageId('Spanish'),
            Languages.getLanguageId('Danish'),
            Languages.getLanguageId('Italian'),
            Languages.getLanguageId('Dutch'),
            Languages.getLanguageId('Swedish'),
            Languages.getLanguageId('Chinese'),
            Languages.getLanguageId('German'),
            (frenchId, spanishId, danishId, italianId, dutchId, swedishId, chineseId, germanId) => {
                return DbCoreDescription.buildFromProductOptionDescriptions(descriptions.getFrench(), prodFakeIdx, optFakeIdx, frenchId)
                    .concat(DbCoreDescription.buildFromProductOptionDescriptions(descriptions.getSpanish(), prodFakeIdx, optFakeIdx, spanishId))
                    .concat(DbCoreDescription.buildFromProductOptionDescriptions(descriptions.getDanish(), prodFakeIdx, optFakeIdx, danishId))
                    .concat(DbCoreDescription.buildFromProductOptionDescriptions(descriptions.getItalian(), prodFakeIdx, optFakeIdx, italianId))
                    .concat(DbCoreDescription.buildFromProductOptionDescriptions(descriptions.getDutch(), prodFakeIdx, optFakeIdx, dutchId))
                    .concat(DbCoreDescription.buildFromProductOptionDescriptions(descriptions.getSwedish(), prodFakeIdx, optFakeIdx, swedishId))
                    .concat(DbCoreDescription.buildFromProductOptionDescriptions(descriptions.getChinese(), prodFakeIdx, optFakeIdx, chineseId))
                    .concat(DbCoreDescription.buildFromProductOptionDescriptions(descriptions.getGerman(), prodFakeIdx, optFakeIdx, germanId));
            }).getOrElse(List());
    }

    static buildFromProductOptions(
        product: Product,
        parentFakeIdx: Option<number> = None,
    ): List<DbCoreDescription> {
        return product.options.flatMap((x, idx) => DbCoreDescription.buildFromProductOption(
            product,
            x,
            parentFakeIdx,
            Some(idx)));
    }

    static buildFromProducts(
        products: List<Product>,
        parentFakeIdx: Option<number> = None,
    ): List<DbCoreDescription> {
        return products.flatMap((x, idx) => DbCoreDescription.buildFromProduct(
            x,
            OptionUtils.applyOrReturnNonEmpty(parentFakeIdx, Some(idx), (a, b) => a * 1000 + b)));
    }

    validate(): ValidationResult {
        return List.of(
            ValidationUtils.validateNvarchar('description', this.description, sqlTextMax),
            ValidationUtils.validateInt('langId', this.langId.toString()),
            ValidationUtils.validateInt('typeId', this.typeId.toString()),
            ValidationUtils.validateInt('lenId', this.lenId.toString()),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }
}

export class DbCoreDescriptionJsonSerializer extends SimpleJsonSerializer<DbCoreDescription> {
    static instance: DbCoreDescriptionJsonSerializer = new DbCoreDescriptionJsonSerializer();

    fromJsonImpl(obj: any): DbCoreDescription {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(description: DbCoreDescription, builder: JsonBuilder): JsonBuilder {
        return builder
            .add(descriptionKey, description.description)
            .add(langIdKey, description.langId)
            .add(typeIdKey, description.typeId)
            .add(lengthIdKey, description.lenId)
            .addOptional(optFakeIdxKey, description.optFakeIdx)
            .addOptional(prodFakeIdxKey, description.prodFakeIdx);

    }
}
