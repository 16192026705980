import {Either, None, Option, Some} from 'funfix-core';
import {findNearest, getLatitude, getLongitude} from 'geolib';
import {List, Set} from 'immutable';
import {ValueComparator} from 'ts-comparators';
import {
    companyKey,
    ComparisonUtils,
    contactKey,
    descriptionsKey,
    directionsKey,
    EitherUtils,
    exclusionsKey,
    extraContentKey,
    idKey,
    inclusionsKey,
    JsonBuilder,
    Language,
    locationKey,
    logoKey,
    mapKey,
    mappingKey,
    mediaKey,
    meetingPointKey,
    nameKey,
    optionsKey,
    OptionUtils,
    parseListSerializable,
    parseNumber,
    parseString,
    productOptionIdKey,
    ratingKey,
    SimpleJsonSerializer,
    socialKey,
    sortingKey,
    StringSearchType,
    StringUtils,
    subproductsKey,
    translationsKey,
    typeKey,
} from '../core';
import {Company, CompanyJsonSerializer} from './company';
import {Contact, ContactJsonSerializer} from './contact';
import {ProductContentCollection, ProductContentCollectionJsonSerializer} from './content-collection';
import {DescriptionTranslations, DescriptionTranslationsJsonSerializer} from './description-translations';
import {Descriptions, DescriptionsJsonSerializer} from './descriptions';
import {Directions, DirectionsJsonSerializer} from './directions';
import {Distance} from './distance';
import {GMap, GoogleMapJsonSerializer} from './gmap';
import {GpsLocation, GpsLocationJsonSerializer} from './gps-location';
import {Image, imageComparator, ImageJsonSerializer} from './image';
import {LatLongLocation} from './lat-long-location';
import {MapMarker} from './map-marker';
import {Mapping, MappingJsonSerializer} from './mapping';
import {MediaLibrary, MediaLibraryJsonSerializer} from './media-library';
import {ProductOption, ProductOptionJsonSerializer} from './product-option';
import {ProductScore} from './product-rating';
import {ProductAndCompanySearchField, ProductSearchType} from './product-search';
import {Rating, RatingUtils} from './rating';
import {Social, SocialJsonSerializer} from './social';
import {Video} from './video';

export type ProductStringSearchField = 'Name' | 'City' | 'Country' | 'State' | 'Type';
export type ProductMomentSearchField = 'Created' | 'Modified';
export type ProductNumberSearchField = 'Id' | 'Rating';
export type ProductSearchField = ProductStringSearchField | ProductNumberSearchField | ProductMomentSearchField | 'Fuzzy' | 'LatLong';

export class ProductLike extends LatLongLocation {

    static subproductSizeComparator = new ValueComparator<ProductLike, number>(e => e.getSubproducts().size, ComparisonUtils.numberComparator);

    buildProduct(): Option<Product> {
        return Option.of(
            new Product(
                this.getId(),
                this.getProductName(),
                this.getProductType(),
                this.getDescriptions(),
                this.getMedia(),
                this.getOrdering(),
                this.getRating(),
                this.getLocation(),
                this.getMap(),
                this.getLogo(),
                this.getSupplier(),
                this.getContact(),
                this.getSocial(),
                this.getDirections(),
                this.getOptions(),
                this.getMapping(),
                this.getProductOptionId(),
                this.getExtraContent(),
                this.getTranslations(),
                this.getInclusions(),
                this.getExclusions(),
                this.getMeetingPoint(),
                this.getSubproducts(),
            ),
        );
    }

    buildProductEither(): Either<string, Product> {
        return EitherUtils.toEither(this.buildProduct(), 'Failed to build Product');
    }

    distanceFrom(prod: ProductLike): Option<Distance> {
        return this.getLatLongLocation().getStraightLineDistance(prod.getLatLongLocation());
    }

    findNearest(prods: List<ProductLike>): Option<ProductLike> {
        const prodPts = OptionUtils.flattenList(prods.map(x => x.getLatLongLocation().getPoints()));

        if (prodPts.isEmpty()) {
            return None;
        }

        const prod = this.getLatLongLocation().getPoints()
            .map(pts => findNearest(pts, prodPts.toArray()))
            .flatMap(pts =>
                Option.of(prods.find(x => x.getLatitude().contains(getLatitude(pts)) && x.getLongitude().contains(getLongitude(pts)))));

        // Note: Pick the product with the most subproducts if there are multiple destinations withing 1km of each other
        return prod.flatMap(x => Option.of(x.findWithin(prods, Distance.km(1))
            .sort((a, b) => ProductLike.subproductSizeComparator.compare(a, b))
            .first()));
    }

    findWithin(products: List<ProductLike>, distance: Distance): List<ProductLike> {
        return products.filter(x => x.isNear(this.getLatLongLocation(), distance));
    }

    getCity(): Option<string> {
        return this.getLocation().flatMap(x => x.city);
    }

    getCompanyId(): Option<number> {
        return this.getSupplierId();
    }

    getContact(): Option<Contact> {
        return None;
    }

    getCountry(): Option<string> {
        return this.getLocation().flatMap(x => x.country);
    }

    getDescriptions(): Option<Descriptions> {
        return None;
    }

    getDirections(): Option<Directions> {
        return None;
    }

    getDistanceFromAirport(): Option<Distance> {
        return this.getExtraContent().flatMap(x => x.location).flatMap(x => x.distanceFromAirport);
    }

    getDistanceFromCityCenter(): Option<Distance> {
        return this.getExtraContent().flatMap(x => x.location).flatMap(x => x.distanceFromCityCenter);
    }

    getExclusions(): Option<string> {
        return None;
    }

    getExtraContent(): Option<ProductContentCollection> {
        return None;
    }

    getFirstApiReference(): Option<string> {
        return Option.of(this.getMapping().first()).flatMap(x => x.optcode);
    }

    getGettingThere(): Option<string> {
        return this.getExtraContent().flatMap(x => x.location).flatMap(x => x.gettingThere);
    }

    getId(): Option<number> {
        return None;
    }

    getImageCount(): number {
        return this.getMedia()
            .map(x => x.images.size)
            .getOrElse(0);
    }

    getImages(): List<Image> {
        return OptionUtils.toList(this.getMedia()).flatMap(x => x.images)
            .sort((a, b) => imageComparator.compare(a, b));
    }

    getImageUrls(): List<string> {
        return OptionUtils.flattenList(this.getImages().map(x => x.uri)).map(x => x.getHref());
    }

    getInclusions(): Option<string> {
        return None;
    }

    // Uses directions start if it exists
    getLatitude(): Option<number> {
        return this.getLatLongLocation().getLatitude();
    }

    getLatLongLocation(): LatLongLocation {
        const directionsBase = this.getDirections().filter(x => x.isUseful()).flatMap(x => x.getStartLatLongLocation());
        const mapBased = this.getMap().filter(x => x.isUseful()).flatMap(x => Option.of(x.marker.first()));
        const locationBased = this.getLocation().flatMap(x => x.getGpsCoords()).map(x => x.getLatLongLocation());
        return directionsBase
            .orElse(locationBased)
            .orElse(mapBased)
            .getOrElse(new LatLongLocation());
    }

    getLocation(): Option<GpsLocation> {
        return None;
    }

    getLogo(): Option<Image> {
        return None;
    }

    getLongDescription(): Option<string> {
        return None;
    }

    // Uses directions start if it exists
    getLongitude(): Option<number> {
        return this.getLatLongLocation().getLongitude();
    }

    getMap(): Option<GMap> {
        return None;
    }

    getMapping(): List<Mapping> {
        return List();
    }

    getMedia(): Option<MediaLibrary> {
        return None;
    }

    getMeetingPoint(): Option<string> {
        return None;
    }

    getOptionCount(): number {
        return this.getOptions().size;
    }

    getOptionImageCount(): number {
        return this.getOptions().reduce((acc, o) => acc + o.getProductImageCount(), 0);
    }

    getOptions(): List<ProductOption> {
        return List();
    }

    getOptionVideoCount(): number {
        return this.getOptions().reduce((acc, o) => acc + o.getProductVideoCount(), 0);
    }

    getOrdering(): Option<string> {
        return None;
    }

    getPoints(): Option<{ latitude: number; longitude: number }> {
        return this.getLatLongLocation().getPoints();
    }

    getPointsAlternate(): Option<{ lat: number; lng: number }> {
        return this.getLatLongLocation().getPointsAlternate();
    }

    getProductName(): Option<string> {
        return None;
    }

    getProductOptionId(): Option<number> {
        return None;
    }

    getProductType(): Option<string> {
        return None;
    }

    getQuickstartId(): Option<string> {
        return this.getId().map(i => btoa(`PD${i}`));
    }

    getRating(): Option<number> {
        return None;
    }

    getSearchString(field: ProductAndCompanySearchField): Option<string> {
        switch (field) {
            case 'Company:Name':
                return this.getSupplierName();
            case 'Company:City':
                return this.getSupplier().flatMap(x => x.getLocation()).flatMap(x => x.getCity());
            case 'Company:Country':
                return this.getSupplier().flatMap(x => x.getLocation()).flatMap(x => x.getCountry());
            case 'Company:State':
                return this.getSupplier().flatMap(x => x.getLocation()).flatMap(x => x.getState());
            case 'Company:Type':
                return this.getSupplier().flatMap(x => x.getType());
            case 'Company:Id':
                return this.getSupplier().flatMap(x => x.getId()).map(x => x.toString());
            case 'Id':
                return this.getId().map(x => x.toString());
            case 'Rating':
                return this.getRating().map(x => x.toString());
            case 'Created':
                return None;
            case 'Modified':
                return None;
            case 'Type':
                return this.getProductType();
            case 'Name':
                return this.getProductName();
            case 'City':
                return this.getCity();
            case 'Country':
                return this.getCountry();
            case 'State':
                return this.getState();
            case 'Fuzzy':
            default:
                return None;

        }
    }

    getShortDescription(): Option<string> {
        return None;
    }

    getSingleLineSummary(): string {
        return OptionUtils.toList(
            Option.of(this.getMapping().first())
                .flatMap(x => x.optcode),
            this.getProductType().orElse(Some('Unknown Type')),
            this.getId().map(x => x.toString()),
            this.getSupplier()
                .flatMap(x => x.name)
                .orElse(Some('Company Owned')),
            this.getProductName(),
            Some(`${this.getOptions().size} Options`).filter(_ => !this.getOptions().isEmpty()),
            this.getDescriptions()
                .filter(x => !x.isEmpty())
                .map(_ => 'Has Descriptions'),
            this.getMedia()
                .map(i => `${i.images.size} Images`)
                .filter(_ =>
                    !OptionUtils.toList(this.getMedia())
                        .flatMap(x => x.images)
                        .isEmpty(),
                ),
            this.getMedia()
                .map(i => `${i.videos.size} Videos`)
                .filter(_ =>
                    !OptionUtils.toList(this.getMedia())
                        .flatMap(x => x.videos)
                        .isEmpty(),
                ),
            Some(`${this.getMapping().size} Mapping Codes`).filter(_ => !this.getMapping().isEmpty()),
            this.getRating().map(x => `${x} Star`),
            this.getLogo().map(_ => 'Has Logo'),
            this.getContact()
                .filter(x => !x.isEmpty())
                .map(_ => 'Has Contact'),
            this.getLocation()
                .filter(x => !x.isEmpty())
                .map(_ => 'Has Location'),
        ).reduce((a, b) => a + ' - ' + b, '');
    }

    getSocial(): Option<Social> {
        return None;
    }

    getSortedImages(count: number): List<Image> {
        return this.getImages().take(count);
    }

    getState(): Option<string> {
        return this.getLocation().flatMap(x => x.state);
    }

    getStraightLineDistance(other: LatLongLocation): Option<Distance> {
        return this.getLatLongLocation().getStraightLineDistance(other);
    }

    getSubproducts(): List<Product> {
        return List();
    }

    getSupplier(): Option<Company> {
        return None;
    }

    getSupplierId(): Option<number> {
        return this.getSupplier().flatMap(x => x.id);
    }

    getSupplierName(): Option<string> {
        return this.getSupplier().flatMap(x => x.name);
    }

    getTranslations(): Option<DescriptionTranslations> {
        return None;
    }

    getVideoCount(): number {
        return this.getMedia()
            .map(x => x.images.size)
            .getOrElse(0);
    }

    getVideos(): List<Video> {
        return OptionUtils.toList(this.getMedia()).flatMap(x => x.videos);
    }

    // HACK: Does not check name, id or type
    hasNoUpdates(): boolean {
        return (
            this.getDescriptions().forAll(d => d.isEmpty()) &&
            this.getMedia().forAll(d => d.isEmpty()) &&
            this.getOrdering().isEmpty() &&
            this.getRating().isEmpty() &&
            this.getLocation().forAll(d => d.isEmpty()) &&
            this.getMap().forAll(d => d.isEmpty()) &&
            this.getLogo().isEmpty() &&
            this.getSupplier().forAll(d => d.isEmpty()) &&
            this.getContact().forAll(d => d.isEmpty()) &&
            this.getSocial().forAll(d => d.isEmpty()) &&
            this.getDirections().forAll(d => d.isEmpty()) &&
            this.getOptions().isEmpty() &&
            this.getMapping().isEmpty()
        );
    }

    isAccessibleToOneOfProposalCompanies(s: Set<number>): boolean {
        return s.some(x => this.isSupplierOwned() || this.isProposalCompanyOwner(x));
    }

    isAccommodation(): boolean {
        return this.getProductType().contains('Accommodation');
    }

    isDayTour(): boolean {
        return this.getProductType().contains('Day Tour / Attraction');
    }

    isDestination(): boolean {
        return this.getProductType().contains('Destination');
    }

    isEmpty(): boolean {
        return this.getId().isEmpty() && this.getProductName().isEmpty() && this.getProductType().isEmpty() && this.hasNoUpdates();
    }

    isMultidayTour(): boolean {
        return this.getProductType().contains('Multiday');
    }

    isProposalCompanyOwned(): boolean {
        return this.getSupplier().exists(x => x.isProposalCompany());
    }

    isProposalCompanyOwner(proposalCompanyId: number): boolean {
        return (
            this.isProposalCompanyOwned() &&
            this.getSupplier().exists(supplier => supplier.id.contains(proposalCompanyId))
        );
    }

    isSelfDrive(): boolean {
        return this.isDestination() && this.getDirections().exists(x => !x.image.isEmpty());
    }

    isStandardDestination(): boolean {
        return this.isDestination() && !this.isSelfDrive();
    }

    isSupplierOwned(): boolean {
        return this.getSupplier().exists(x => x.isSupplier());
    }

    isSupplierOwner(supplierId: number): boolean {
        return this.isSupplierOwned() && this.getSupplier().exists(supplier => supplier.id.contains(supplierId));
    }

    matchesSearch(field: ProductAndCompanySearchField, caseSensitive: boolean, comparison: string, type: ProductSearchType): boolean {
        if (field === 'Fuzzy') {
            return this.matchesSearch('Name', caseSensitive, comparison, type)
                || Set.of<ProductAndCompanySearchField>('Id', 'City', 'Country', 'State', 'Type', 'Company:Id', 'Company:Name')
                    .some(x => this.matchesSearch(x, caseSensitive, comparison, 'Exact'));
        } else if (field === 'LatLong') {
            const radius = parseNumber(type.split('-')[1]);
            const latlong = LatLongLocation.buildFromText(comparison);
            return this.getStraightLineDistance(latlong).exists(x => x.getKilometers() <= radius.getOrElse(0));
        }

        return this.getSearchString(field)
            .exists(str => StringUtils.stringSearchMatch(caseSensitive, str, comparison, type as StringSearchType));
    }

    nonEmpty(): boolean {
        return !this.isEmpty();
    }
}

/**
 * Note: Partial Implementation
 */
export class Product extends ProductLike {

    constructor(
        readonly id: Option<number> = None,
        readonly name: Option<string> = None,
        readonly type: Option<string> = None,
        readonly descriptions: Option<Descriptions> = None,
        readonly media: Option<MediaLibrary> = None,
        readonly ordering: Option<string> = None,
        readonly rating: Option<number> = None,
        readonly location: Option<GpsLocation> = None,
        readonly map: Option<GMap> = None,
        readonly logo: Option<Image> = None,
        readonly supplier: Option<Company> = None,
        readonly contact: Option<Contact> = None,
        readonly social: Option<Social> = None,
        readonly directions: Option<Directions> = None,
        readonly options: List<ProductOption> = List(),
        readonly mapping: List<Mapping> = List(),
        readonly productOptionId: Option<number> = None,
        readonly extraContent: Option<ProductContentCollection> = None,
        readonly translations: Option<DescriptionTranslations> = None,
        readonly inclusions: Option<string> = None,
        readonly exclusions: Option<string> = None,
        readonly meetingPoint: Option<string> = None,
        readonly subproducts: List<Product> = List(),
    ) {
        super();
    }

    static parseProductSearchField(input: string): Option<ProductStringSearchField> {
        switch (input) {
            case 'Type':
            case 'Name':
            case 'City':
            case 'Country':
            case 'State':
                return Some(input as ProductStringSearchField);
            default:
                return None;
        }
    }

    // Note: This is not a "Diff", this is just fields
    // we can safely add to the DB if they are missing from whats present
    // It is assumed that "this" is the current state in the db and "prod"
    // is a product thats matched it.
    // Important: Product options are not computed here
    calculateUpdates(prod: Product): Product {
        return new Product(
            this.getId(),
            this.getProductName(),
            this.getProductType(),
            OptionUtils.applyOrReturnNonEmpty(this.getDescriptions(), prod.getDescriptions(), (a, b) => a.calculateUpdates(b)),
            this.getMedia().orElse(prod.getMedia()),
            this.getOrdering(),
            this.getRating().orElse(prod.getRating()),
            this.getLocation().orElse(prod.getLocation()),
            this.getMap().orElse(prod.getMap()),
            this.getLogo().orElse(prod.getLogo()),
            this.getSupplier().orElse(prod.getSupplier()),
            OptionUtils.applyOrReturnNonEmpty(this.getContact(), prod.getContact(), (a, b) => a.calculateUpdates(b)),
            OptionUtils.applyOrReturnNonEmpty(this.getSocial(), prod.getSocial(), (a, b) => a.calculateUpdates(b)),
            this.getDirections().orElse(prod.getDirections()),
            this.getOptions(),
            this.getMapping(),
            this.getProductOptionId(),
            this.getExtraContent(),
            this.getTranslations(),
            this.getInclusions(),
            this.getExclusions(),
            this.getMeetingPoint(),
            this.getSubproducts(),
        );
    }

    getCompanyId(): Option<number> {
        return this.getSupplierId();
    }

    getContact(): Option<Contact> {
        return this.contact;
    }

    getDefaultZoom(): Option<number> {
        if (this.isDayTour() || this.isMultidayTour()) {
            return Some(13);
        }

        if (this.isDestination()) {
            return Some(12);
        }

        if (this.isAccommodation()) {
            return Some(15);
        }

        return Some(13);
    }

    getDescriptions(): Option<Descriptions> {
        return this.descriptions;
    }

    getDirections(): Option<Directions> {
        return this.directions;
    }

    getExclusions(): Option<string> {
        return this.exclusions;
    }

    getExtraContent(): Option<ProductContentCollection> {
        return this.extraContent;
    }

    getId(): Option<number> {
        return this.id;
    }

    getInclusions(): Option<string> {
        return this.inclusions;
    }

    getLocation(): Option<GpsLocation> {
        return this.location;
    }

    getLogo(): Option<Image> {
        return this.logo;
    }

    getLongDescription(): Option<string> {
        return this.getDescriptions()
            .flatMap(x => x.getLong());
    }

    getMap(): Option<GMap> {
        return this.map;
    }

    getMapping(): List<Mapping> {
        return this.mapping;
    }

    getMedia(): Option<MediaLibrary> {
        return this.media;
    }

    getMeetingPoint(): Option<string> {
        return this.meetingPoint;
    }

    getOptionCountRating(): Rating {
        if (!this.isAccommodation()) {
            return 'Gold';
        } else if (this.subproducts.size > 10) {
            return 'Gold';
        } else if (this.subproducts.size > 5) {
            return 'Silver';
        } else if (this.subproducts.size > 2) {
            return 'Bronze';
        }
        return 'Black';
    }

    getOptions(): List<ProductOption> {
        return this.options;
    }

    getOrdering(): Option<string> {
        return this.ordering;
    }

    getOverallScore(): Rating {
        return this.getRelevantRatingAverage();
    }

    getProductName(): Option<string> {
        return this.name;
    }

    getProductOptionId(): Option<number> {
        return this.productOptionId;
    }

    getProductScore(): ProductScore {
        return new ProductScore(
            this.getMedia().map(x => x.getImageRating()).getOrElse('Black'),
            this.getMedia().map(x => x.getImageCountRating()).getOrElse('Black'),
            this.getLocation().map(x => x.getRating()).getOrElse('Black'),
            RatingUtils.average(this.options.map(x => x.getOverallRating())),
            this.getOptionCountRating(),
            RatingUtils.average(this.subproducts.map(x => x.getOverallScore())),
            this.getSubproductCountRating(),
            this.getMedia().map(x => x.getVideoRating()).getOrElse('Black'),
            this.getContact().map(x => x.getCompanyContactRating()).getOrElse('Black'),
        );
    }

    getProductType(): Option<string> {
        return this.type;
    }

    getRating(): Option<number> {
        return this.rating;
    }

    private getRelevantRatingAverage(): Rating {
        return RatingUtils.average(this.getRelevantRatings());
    }

    private getRelevantRatingMinimum(): Rating {
        return RatingUtils.average(this.getRelevantRatings());
    }

    private getRelevantRatings(): List<Rating> {
        const score = this.getProductScore();
        // Destinations and self drives dont have options or contact
        if (this.isDestination()) {
            return List.of(
                score.imageRating,
                score.imageCountRating,
                score.locationRating);
        }

        return List.of(
            score.imageRating,
            score.imageCountRating,
            score.locationRating,
            score.contactRating,
            score.optionRating);
    }

    private getRelevantRoadbookRatingMinimum(): Rating {
        return RatingUtils.average(this.getRelevantRoadbookRatings());
    }

    private getRelevantRoadbookRatings(): List<Rating> {
        const score = this.getProductScore();
        return List.of(
            score.imageRating,
            score.imageCountRating,
            score.locationRating,
            score.contactRating,
            score.optionRating,
            score.subproductCount,
            score.subproductRating);
    }

    getRoadbookOverallScore(): Rating {
        return this.getRelevantRoadbookRatingMinimum();
    }

    getShortDescription(): Option<string> {
        return this.getDescriptions()
            .flatMap(x => x.getShort());
    }

    getSocial(): Option<Social> {
        return this.social;
    }

    getSubproductCountRating(): Rating {
        if (!(this.isDestination() || this.isSelfDrive())) {
            return 'Gold';
        } else if (this.subproducts.size > 20) {
            return 'Gold';
        } else if (this.subproducts.size > 10) {
            return 'Silver';
        } else if (this.subproducts.size > 20) {
            return 'Bronze';
        }
        return 'Black';
    }

    getSubproducts(): List<Product> {
        return this.subproducts;
    }

    getSupplier(): Option<Company> {
        return this.supplier;
    }

    getSupportedLanguageCodes(): List<string> {
        return OptionUtils.toList(this.getDescriptions().map(x => 'en'))
            .concat(OptionUtils.toList(this.getTranslations()).flatMap(x => x.getSupportedLanguageCodes()));
    }

    getTranslatedDescriptions(language: Language): Option<Descriptions> {
        switch (language) {
            case 'Chinese':
                return this.getTranslations()
                    .flatMap(x => x.getChinese());
            case 'Danish':
                return this.getTranslations()
                    .flatMap(x => x.getDanish());
            case 'French':
                return this.getTranslations()
                    .flatMap(x => x.getFrench());
            case 'German':
                return this.getTranslations()
                    .flatMap(x => x.getGerman());
            case 'Italian':
                return this.getTranslations()
                    .flatMap(x => x.getItalian());
            case 'Spanish':
                return this.getTranslations()
                    .flatMap(x => x.getSpanish());
            case 'Dutch':
                return this.getTranslations()
                    .flatMap(x => x.getDutch());
            case 'Swedish':
                return this.getTranslations()
                    .flatMap(x => x.getSwedish());
            case 'English':
                return this.getDescriptions();
            default:
                return this.getDescriptions();
        }
    }

    getTranslatedExclusions(language: Language): Option<string> {
        return this.getTranslatedDescriptions(language)
            .flatMap(x => x.getExclusions());
    }

    getTranslatedInclusions(language: Language): Option<string> {
        return this.getTranslatedDescriptions(language)
            .flatMap(x => x.getInclusions());
    }

    getTranslatedLongDescription(language: Language): Option<string> {
        return this.getTranslatedDescriptions(language)
            .flatMap(x => x.getLong());
    }

    getTranslatedMeetingPoint(language: Language): Option<string> {
        return this.getTranslatedDescriptions(language)
            .flatMap(x => x.getMeetingPoint());
    }

    getTranslatedShortDescription(language: Language): Option<string> {
        return this.getTranslatedDescriptions(language)
            .flatMap(x => x.getShort());
    }

    getTranslatedTitle(language: Language): Option<string> {
        return this.getTranslatedDescriptions(language)
            .flatMap(x => x.getTitle());
    }

    getTranslations(): Option<DescriptionTranslations> {
        return this.translations;
    }

    transformSubproducts(f: (p: Product) => Product): Product {
        return this.withSubproducts(this.getSubproducts().map(f));
    }

    withId(id: Option<number>): Product {
        return new Product(
            id,
            this.getProductName(),
            this.getProductType(),
            this.getDescriptions(),
            this.getMedia(),
            this.getOrdering(),
            this.getRating(),
            this.getLocation(),
            this.getMap(),
            this.getLogo(),
            this.getSupplier(),
            this.getContact(),
            this.getSocial(),
            this.getDirections(),
            this.getOptions(),
            this.getMapping(),
            this.getProductOptionId(),
            this.getExtraContent(),
            this.getTranslations(),
            this.getInclusions(),
            this.getExclusions(),
            this.getMeetingPoint(),
            this.getSubproducts(),
        );
    }

    withImageUrls(imageUrls: List<string>): Product {
        return new Product(
            this.id,
            this.name,
            this.type,
            this.descriptions,
            Some(MediaLibrary.fromUrls(imageUrls)),
            this.ordering,
            this.rating,
            this.location,
            this.map,
            this.logo,
            this.supplier,
            this.contact,
            this.social,
            this.directions,
            this.options,
            this.mapping,
            this.productOptionId,
            this.extraContent,
            this.translations,
            this.inclusions,
            this.exclusions,
            this.meetingPoint,
            this.subproducts,
        );
    }

    withLocation(gpsLocation: GpsLocation): Product {
        return new Product(
            this.getId(),
            this.getProductName(),
            this.getProductType(),
            this.getDescriptions(),
            this.getMedia(),
            this.getOrdering(),
            this.getRating(),
            Some(gpsLocation),
            this.getMap(),
            this.getLogo(),
            this.getSupplier(),
            this.getContact(),
            this.getSocial(),
            this.getDirections(),
            this.getOptions(),
            this.getMapping(),
            this.getProductOptionId(),
            this.getExtraContent(),
            this.getTranslations(),
            this.getInclusions(),
            this.getExclusions(),
            this.getMeetingPoint(),
            this.getSubproducts(),
        );
    }

    withMap(filename: string): Product {
        return new Product(
            this.getId(),
            this.getProductName(),
            this.getProductType(),
            this.getDescriptions(),
            this.getMedia(),
            this.getOrdering(),
            this.getRating(),
            this.getLocation(),
            Option.of(new GMap(this.getLatitude(), this.getLongitude(), this.getDefaultZoom(), Some(filename), List.of(new MapMarker(this.getLatitude(), this.getLongitude(), None, None, None, None, Some('red'))))),
            this.getLogo(),
            this.getSupplier(),
            this.getContact(),
            this.getSocial(),
            this.getDirections(),
            this.getOptions(),
            this.getMapping(),
            this.getProductOptionId(),
            this.getExtraContent(),
            this.getTranslations(),
            this.getInclusions(),
            this.getExclusions(),
            this.getMeetingPoint(),
            this.getSubproducts(),
        );
    }

    withMappings(mappings: List<Mapping>): Product {
        return new Product(
            this.id,
            this.name,
            this.type,
            this.descriptions,
            this.media,
            this.ordering,
            this.rating,
            this.location,
            this.map,
            this.logo,
            this.supplier,
            this.contact,
            this.social,
            this.directions,
            this.options,
            mappings,
            this.productOptionId,
            this.extraContent,
            this.translations,
            this.inclusions,
            this.exclusions,
            this.meetingPoint,
            this.subproducts,
        );
    }

    withOptions(options: List<ProductOption>, liftMappings: boolean = true): Product {
        const mappingList: List<Mapping> = options.flatMap(o => o.mapping);
        return new Product(
            this.id,
            this.name,
            this.type,
            this.descriptions,
            this.media,
            this.ordering,
            this.rating,
            this.location,
            this.map,
            this.logo,
            this.supplier,
            this.contact,
            this.social,
            this.directions,
            liftMappings ? options.map(x => x.withoutMappings()) : options,
            liftMappings ? mappingList.concat(...this.getMapping().toArray()) : this.getMapping(),
            this.productOptionId,
            this.extraContent,
            this.translations,
            this.inclusions,
            this.exclusions,
            this.meetingPoint,
            this.subproducts,
        );
    }

    withProductOptionId(prodOptId: Option<number>): Product {
        return new Product(
            this.id,
            this.name,
            this.type,
            this.descriptions,
            this.media,
            this.ordering,
            this.rating,
            this.location,
            this.map,
            this.logo,
            this.supplier,
            this.contact,
            this.social,
            this.directions,
            this.options,
            this.mapping,
            prodOptId,
            this.extraContent,
            this.translations,
            this.inclusions,
            this.exclusions,
            this.meetingPoint,
            this.subproducts,
        );
    }

    withRating(rating: Option<number>): Product {
        return new Product(
            this.id,
            this.name,
            this.type,
            this.descriptions,
            this.media,
            this.ordering,
            rating,
            this.location,
            this.map,
            this.logo,
            this.supplier,
            this.contact,
            this.social,
            this.directions,
            this.options,
            this.mapping,
            this.productOptionId,
            this.extraContent,
            this.translations,
            this.inclusions,
            this.exclusions,
            this.meetingPoint,
            this.subproducts,
        );
    }

    withSubproducts(subproducts: List<Product>): Product {
        return new Product(
            this.id,
            this.name,
            this.type,
            this.descriptions,
            this.media,
            this.ordering,
            this.rating,
            this.location,
            this.map,
            this.logo,
            this.supplier,
            this.contact,
            this.social,
            this.directions,
            this.options,
            this.mapping,
            this.productOptionId,
            this.extraContent,
            this.translations,
            this.inclusions,
            this.exclusions,
            this.meetingPoint,
            subproducts);
    }

    withSupplier(company: Option<Company>): Product {
        return new Product(
            this.id,
            this.name,
            this.type,
            this.descriptions,
            this.media,
            this.ordering,
            this.rating,
            this.location,
            this.map,
            this.logo,
            company,
            this.contact,
            this.social,
            this.directions,
            this.options,
            this.mapping,
            this.productOptionId,
            this.extraContent,
            this.translations,
            this.inclusions,
            this.exclusions,
            this.meetingPoint,
            this.subproducts,
        );
    }

    withType(type: Option<string>): Product {
        return new Product(
            this.id,
            this.name,
            type,
            this.descriptions,
            this.media,
            this.ordering,
            this.rating,
            this.location,
            this.map,
            this.logo,
            this.supplier,
            this.contact,
            this.social,
            this.directions,
            this.options,
            this.mapping,
            this.productOptionId,
            this.extraContent,
            this.translations,
            this.inclusions,
            this.exclusions,
            this.meetingPoint,
            this.subproducts,
        );
    }
}

export class ProductJsonSerializer extends SimpleJsonSerializer<Product> {
    static instance: ProductJsonSerializer = new ProductJsonSerializer();

    fromJsonImpl(obj: any): Product {
        return new Product(
            parseNumber(obj[idKey]),
            parseString(obj[nameKey]),
            parseString(obj[typeKey]),
            DescriptionsJsonSerializer.instance.fromJson(obj[descriptionsKey]),
            MediaLibraryJsonSerializer.instance.fromJson(obj[mediaKey]),
            parseString(obj[sortingKey]),
            parseNumber(obj[ratingKey]),
            GpsLocationJsonSerializer.instance.fromJson(obj[locationKey]),
            GoogleMapJsonSerializer.instance.fromJson(obj[mapKey]),
            ImageJsonSerializer.instance.fromJson(obj[logoKey]),
            CompanyJsonSerializer.instance.fromJson(obj[companyKey]),
            ContactJsonSerializer.instance.fromJson(obj[contactKey]),
            SocialJsonSerializer.instance.fromJson(obj[socialKey]),
            DirectionsJsonSerializer.instance.fromJson(obj[directionsKey]),
            parseListSerializable(obj[optionsKey], ProductOptionJsonSerializer.instance),
            parseListSerializable(obj[mappingKey], MappingJsonSerializer.instance),
            parseNumber(obj[productOptionIdKey]),
            ProductContentCollectionJsonSerializer.instance.fromJson(obj[extraContentKey]),
            DescriptionTranslationsJsonSerializer.instance.fromJson(obj[translationsKey]),
            parseString(obj[inclusionsKey]),
            parseString(obj[exclusionsKey]),
            parseString(obj[meetingPointKey]),
            parseListSerializable(obj[subproductsKey], ProductJsonSerializer.instance));
    }

    protected toJsonImpl(product: Product, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(idKey, product.id)
            .addOptional(nameKey, product.name)
            .addOptional(typeKey, product.type)
            .addOptionalSerializable(descriptionsKey, product.descriptions, DescriptionsJsonSerializer.instance)
            .addOptionalSerializable(mediaKey, product.media, MediaLibraryJsonSerializer.instance)
            .addOptional(sortingKey, product.ordering)
            .addOptional(ratingKey, product.rating)
            .addOptionalSerializable(locationKey, product.location, GpsLocationJsonSerializer.instance)
            .addOptionalSerializable(mapKey, product.map, GoogleMapJsonSerializer.instance)
            .addOptionalSerializable(logoKey, product.logo, ImageJsonSerializer.instance)
            .addOptionalSerializable(companyKey, product.supplier, CompanyJsonSerializer.instance)
            .addOptionalSerializable(contactKey, product.contact, ContactJsonSerializer.instance)
            .addOptionalSerializable(socialKey, product.social, SocialJsonSerializer.instance)
            .addOptionalSerializable(directionsKey, product.directions, DirectionsJsonSerializer.instance)
            .addIterableSerializable(optionsKey, product.options, ProductOptionJsonSerializer.instance)
            .addIterableSerializable(mappingKey, product.mapping, MappingJsonSerializer.instance)
            .addOptional(productOptionIdKey, product.productOptionId)
            .addOptionalSerializable(extraContentKey, product.extraContent, ProductContentCollectionJsonSerializer.instance)
            .addOptionalSerializable(translationsKey, product.translations, DescriptionTranslationsJsonSerializer.instance)
            .addOptional(inclusionsKey, product.inclusions)
            .addOptional(exclusionsKey, product.exclusions)
            .addOptional(meetingPointKey, product.meetingPoint)
            .addIterableSerializable(subproductsKey, product.subproducts, ProductJsonSerializer.instance);
    }
}
