import {None, Option, Some} from 'funfix-core';
import {List} from 'immutable';
import * as _s from 'underscore.string';
import {CollectionUtils} from './collection-utils';

export type StringSearchType = 'Contains' | 'Exact' | 'StartsWith' | 'Levenshtein-70' | 'Levenshtein-90';

export class StringUtils {
    static allNumbers: ReadonlyArray<string> = '0123456789'.split('');
    static alphaLower: ReadonlyArray<string> = 'abcdefghijklmnopqrstuvwxyz'.split('');
    static alphaUpper: ReadonlyArray<string> = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

    /**
     * Splits into chunks
     *
     * Mostly used for tourplan api requests
     *
     * eg. 2 chunks with just uppercase would have A => M and N => Z.
     * min and max is min and max length
     */
    static chunkPrefixes(
        minLength: number,
        maxLength: number,
        chunks: number,
        upper: boolean = true,
        lower: boolean = true,
        numeric: boolean = true): List<List<string>> {

        const possibleValues = StringUtils.getPossibleValues(upper, lower, numeric);

        const countPerCharacter = possibleValues.length;

        if (chunks < countPerCharacter) {
            return CollectionUtils.splitIntoChunks(List(possibleValues), chunks)
                .map(charList =>
                    charList.flatMap(char =>
                        CollectionUtils.genListOfIndexes(minLength, Math.abs(maxLength - minLength), false)
                            .map(idx => char + StringUtils.fillString('?', idx - 1))));
        }

        console.error('Currently chunks > character count are not supported');
        return List();
    }

    static createLines(...strings: string[]): string {
        if (strings.length === 0) {
            return '';
        }

        return Option.of(strings.reduce((acc, s) => acc + '\n' + s)).getOrElse('');
    }

    static equalsIgnoringCase(a: string, b: string): boolean {
        return a.localeCompare(b, undefined, {sensitivity: 'base'}) === 0;
    }

    static fillString(char: string, len: number): string {
        return List(Array(len)).reduce((a, b) => a + char, '');
    }

    // Innaccurate
    static firstSentence(s: string): Option<string> {
        return Option.of(s.split('.')[0]).map(x => x + '.');
    }

    static genLineNumbers(s: string): string {
        return StringUtils.prefixLines(s, i => `${i + 1}. `);
    }

    static getLine(s: string, n: number): Option<string> {
        return Option.of(s.split('.')[n - 1]).map(x => x + '.');
    }

    static getPlural(n: number): string {
        return n === 1 ? '' : 's';
    }

    static getPossibleValues(upper: boolean, lower: boolean, numeric: boolean): string[] {
        const possibleValues: string[] = [];

        if (upper) {
            possibleValues.push(...StringUtils.alphaUpper);
        }

        if (lower) {
            possibleValues.push(...StringUtils.alphaLower);
        }

        if (numeric) {
            possibleValues.push(...StringUtils.allNumbers);
        }
        return possibleValues;
    }

    /**
     * TODO: Optimize
     */
    static includesIgnoringCase(a: string, b: string): boolean {
        return a.toLowerCase().includes(b.toLowerCase());
    }

    /**
     * Essentially a basic leftpad
     * prefix to every line
     */
    static indent(s: string, prefix: string): string {
        return StringUtils.prefixLines(s, _ => prefix);
    }

    static isGreaterThenLevensteinPercentage(input: string, comparison: string, percent: number): boolean {
        const max = Math.max(input.length, comparison.length);
        const min = Math.min(input.length, comparison.length);

        // Optimise when string lengths are too different to even bother with levenshtein
        if ((max - min) / max > (1 - percent)) {
            return false;
        }
        const levenshtein = _s.levenshtein(input, comparison);
        return ((max - levenshtein) / max) >= percent;
    }

    static isWithinMatchPercentage(a: string, b: string, percent: number): boolean {
        const max = Math.max(a.length, b.length);
        const distance = _s.levenshtein(a, b);
        const matchPercent = 1 - (distance / max);
        return matchPercent > percent;
    }

    static parseStringSearchType(input: string): Option<StringSearchType> {
        switch (input) {
            case 'Contains':
            case 'Exact':
            case 'StartsWith':
            case 'Levenshtein-70':
            case 'Levenshtein-90':
                return Some(input as StringSearchType);
            default:
                return None;
        }
    }

    // Just appends an S
    static pluralise(s: string, n: number): string {
        return n === 1 ? s : s + 's';
    }

    // Switches word based on plural
    static pluraliseSpecial(singular: string, plural: string, n: number): string {
        return n === 1 ? singular : plural;
    }

    static prefixLines(s: string, prefixGen: (i: number) => string): string {
        return prefixGen(0) + s.split('\n')
            .reduce((a, b, idx) => a + '\n' + prefixGen(idx) + b);
    }

    static searchStringMatch(input: string, comparison: string, type: StringSearchType): boolean {
        switch (type) {
            case 'Contains':
                return input.includes(comparison);
            case 'Exact':
                return input === comparison;
            case 'StartsWith':
                return input.startsWith(comparison);
            case 'Levenshtein-70':
                return this.isGreaterThenLevensteinPercentage(input, comparison, 0.7);
            case 'Levenshtein-90':
                return this.isGreaterThenLevensteinPercentage(input, comparison, 0.9);
        }
    }

    // Ideal sentence length for short descriptions in PDF snapshots
    static shortenedDescription(s: string): Option<string> {
        const shortened = s.substring(0, 200);

        if (shortened.length > 0 && shortened[shortened.length - 1] === '.') {
            return Some(shortened);
        }

        const idx = shortened.lastIndexOf('. ');
        if (idx !== -1) {
            return Some(s.substring(0, idx));
        }
        return StringUtils.firstSentence(s);
    }

    static smartTextJoin(list: List<string>): string {
        if (list.size < 2) {
            return list.first('');
        } else {
            const last = list.last('');
            const withoutLast = list.pop();
            return withoutLast.join(', ') + ' & ' + last;
        }
    }

    static stringSearchMatch(caseSensitive: boolean, input: string, searchFor: string, type: StringSearchType): boolean {
        if (caseSensitive) {
            return this.searchStringMatch(input, searchFor, type);
        }
        return this.searchStringMatch(input.toLowerCase(), searchFor.toLowerCase(), type);
    }

    /**
     * Allows writing of multiline strings with a "Margin"
     * Margin character is '\'
     *
     * This will remove all whitespace and the margin character on every line.
     *
     * Eg.
     *
     * `Foo
     *  | dsasa
     *            |Bar`
     *
     * would become
     *
     * Foo
     *  dsasa
     * Bar
     */
    static stripMargin(s: string): string {
        return s.replace(/^(\s*\|)/gm, '');
    }

    static stripPrefix(s: string, prefix: string): string {
        if (s.length < prefix.length) {
            return s;
        }

        if (_s.startsWith(s, prefix)) {
            return s.substr(prefix.length - 1);
        }
        return s;
    }

    static stripSuffix(s: string, suffix: string): string {
        if (s.length < suffix.length) {
            return s;
        }

        if (_s.endsWith(s, suffix)) {
            return s.substr(0, (s.length - suffix.length));
        }
        return s;
    }

}
