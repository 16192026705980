import {None, Option} from 'funfix-core';
import {List} from 'immutable';
import {
    addressKey,
    cityIdKey,
    countryIdKey,
    idKey,
    JsonBuilder,
    logoKey,
    nameKey,
    OptionUtils,
    phoneKey,
    postcodeKey,
    SimpleJsonSerializer,
    stateIdKey,
    typeIdKey,
    Validatable,
    websiteKey,
} from '../core';
import {ValidationResult} from '../core/validation-result';
import {ValidationUtils} from '../core/validation-utils';
import {Company} from './company';
import {LocationIds} from './location-ids';
import {PhysicalLocation} from './physical-location';

export class DBSupplier implements Validatable {

    constructor(
        readonly id: Option<number> = None,
        readonly name: Option<string> = None,
        readonly typeId: Option<number> = None,
        readonly address: Option<string> = None,
        readonly cityId: Option<number> = None,
        readonly stateId: Option<number> = None,
        readonly countryId: Option<number> = None,
        readonly postcode: Option<string> = None,
        readonly logo: Option<string> = None,
        readonly phone: Option<string> = None,
        readonly website: Option<string> = None,
    ) { }

    static buildFromCompanies(
        companies: List<Company>,
        locationIdFinder: (l: PhysicalLocation) => LocationIds): List<DBSupplier> {
        return companies.map(x => this.buildFromCompany(x, locationIdFinder));
    }

    static buildFromCompany(
        company: Company,
        locationIdFinder: (l: PhysicalLocation) => LocationIds): DBSupplier {

        const location = company.location.map(x => locationIdFinder(x)).getOrElse(new LocationIds());

        return new DBSupplier(
            company.id,
            company.name,
            company.type.map(t => DBSupplier.getCompanyTypeId(t)),
            company.location.flatMap(l => l.address),
            location.cityId,
            location.stateId,
            location.countryId,
            company.location.flatMap(l => l.postcode),
            company.logo,
            company.contact.flatMap(l => l.phone),
            company.contact.flatMap(l => l.website),
        );
    }

    // Note: Currently just using "All", we can refine this later
    static getCompanyTypeId(type: string): number {
        return 9;
    }

    getSummary(): string {
        return OptionUtils.toList<any>(
            this.id,
            this.name,
            this.typeId,
            this.address,
            this.cityId,
            this.stateId,
            this.countryId,
            this.postcode,
            this.website,
            this.phone,
            this.logo)
            .reduce((a, b) => `${a} - ${b}`, '');
    }

    validate(): ValidationResult {
        return OptionUtils.toList(
            this.id.map(n => ValidationUtils.validateInt('id', n.toString())),
            this.name.map(n => ValidationUtils.validateTitleNvarchar('name', n, 255)),
            this.typeId.map(n => ValidationUtils.validateInt('typeId', n.toString())),
            this.address.map(n => ValidationUtils.validateNvarchar('ratingId', n, 255)),
            this.cityId.map(n => ValidationUtils.validateInt('cityId', n.toString())),
            this.stateId.map(n => ValidationUtils.validateInt('stateId', n.toString())),
            this.countryId.map(n => ValidationUtils.validateInt('countryId', n.toString())),
            this.postcode.map(n => ValidationUtils.validateNvarchar('postcode', n, 50)),
            this.logo.map(n => ValidationUtils.validateNvarchar('logo', n, 400)),
            this.phone.map(n => ValidationUtils.validatePhone('phone', n, 100)),
            this.website.map(n => ValidationUtils.validateUrl('website', n, 255)),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }
}

export class DBSupplierJsonSerializer extends SimpleJsonSerializer<DBSupplier> {
    static instance: DBSupplierJsonSerializer = new DBSupplierJsonSerializer();

    fromJsonImpl(obj: any): DBSupplier {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(company: DBSupplier, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(idKey, company.id)
            .addOptional(nameKey, company.name)
            .addOptional(typeIdKey, company.typeId)
            .addOptional(addressKey, company.address)
            .addOptional(cityIdKey, company.cityId)
            .addOptional(stateIdKey, company.stateId)
            .addOptional(countryIdKey, company.countryId)
            .addOptional(postcodeKey, company.postcode)
            .addOptional(websiteKey, company.website)
            .addOptional(phoneKey, company.phone)
            .addOptional(logoKey, company.logo);
    }
}
